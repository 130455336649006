import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@compiled/react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import {
	WATCH_BUTTON_KEY,
	PAGE_FAVOURITE_KEY,
	PAGE_UNFAVOURITE_KEY,
	EDIT_BUTTON_PAGE_KEY,
	EDIT_BUTTON_BLOGPOST_KEY,
	INVITE_TO_EDIT_KEY,
} from '@confluence/web-item';
import { ContentToolsWrapper } from '@confluence/content-tools';
import { EditContentButtonWrapper } from '@confluence/edit-button';
import { HeaderItemsContainer } from '@confluence/header-items-manager';

export const FILTERED_WEB_ITEM_KEYS = [
	WATCH_BUTTON_KEY,
	PAGE_FAVOURITE_KEY,
	PAGE_UNFAVOURITE_KEY,
	EDIT_BUTTON_PAGE_KEY,
	EDIT_BUTTON_BLOGPOST_KEY,
	INVITE_TO_EDIT_KEY,
];

const displayInlineBlock = {
	display: 'inline-block',
};

const displayFlex = {
	display: 'flex',
	alignItems: 'center',
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexNavigationContainer = styled.div({
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	minHeight: '52px',
});

export const GroupedPageButtonsCommon = React.memo((props) => (
	<Subscribe to={[HeaderItemsContainer]}>
		{({ initItems }) => (
			<GroupedPageButtonsCommonWithHeaderItems initItems={initItems} {...props} />
		)}
	</Subscribe>
));

GroupedPageButtonsCommon.propTypes = {
	spaceKey: PropTypes.string.isRequired,
	contentId: PropTypes.string.isRequired,
	versionOverride: PropTypes.number,
	showEdit: PropTypes.bool,
	isFabricPage: PropTypes.bool,
	allowedContentToolsItems: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.any,
	contentType: PropTypes.string,
};

/* eslint-disable react/prop-types */
class GroupedPageButtonsCommonWithHeaderItems extends PureComponent {
	constructor(props) {
		super(props);
		props.initItems();
	}

	render() {
		const {
			spaceKey,
			contentId,
			versionOverride,
			showEdit = true,
			isFabricPage,
			allowedContentToolsItems,
			children,
			contentType,
			isExternalCollaborator,
			isContentToolsForcedOpen,
			setIsContentToolsForcedOpen,
		} = this.props;
		return (
			<span data-id="gpb-common" data-testid="gpb-common">
				<FlexNavigationContainer>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
					<span style={displayInlineBlock}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
						<span data-test-id="content-buttons" style={displayFlex}>
							{/* The showEdit prop is used for Embedded View Page to show/hide Edit pencil icon.
              For confluence we set the default value to true. */}
							{showEdit === true ? (
								<EditContentButtonWrapper key="edit-content-button" contentId={contentId} />
							) : null}
							{children}
						</span>
					</span>
					<ContentToolsWrapper
						spaceKey={spaceKey}
						contentId={contentId}
						version={versionOverride}
						isFabricPage={isFabricPage}
						allowedContentToolsItems={allowedContentToolsItems}
						contentType={contentType}
						isExternalCollaborator={isExternalCollaborator}
						isContentToolsForcedOpen={isContentToolsForcedOpen}
						setIsContentToolsForcedOpen={setIsContentToolsForcedOpen}
					/>
				</FlexNavigationContainer>
			</span>
		);
	}
}
